import React from 'react'
import NotFoundContent from "../components/NotFound/NotFound"

const NotFound = () => {
  return (
    <div className="subpage-container">
      {/* <h1>404</h1>
      <h2>Not Found Page</h2> */}
      <NotFoundContent />
    </div>
  )
}

export default NotFound
