import styled from "styled-components"

export default styled.section`
    background-color: var(--white);

    .container {
        max-width: 100rem;
        text-align:center;
        margin-bottom: 130px;
        color: var(--red);
    }

    .large-block {
        color: var(--white);
        margin: 100px auto 80px auto;
        background-color: var(--red);
        border-radius: 50px;
        font-size: 27rem;
        width: 42.5rem;

        @media only screen and (max-width: 1000px){
            width: 34rem;
        }

        @media only screen and (max-width: 600px) {
            width: 24rem;
            margin: 80px auto 40px auto ;
            border-radius: 28px;
        }
    }

    .large-block-text {
        font-size: 278px;
        letter-spacing: -35px;
        line-height: 19rem;
        text-align: center;
        transform: translate(-15px);

        @media only screen and (max-width: 1000px){
            font-size: 225px;
            letter-spacing: -30px;
            line-height: 16rem;
        }

         @media only screen and (max-width: 600px) {
            font-size: 160px;
            line-height: 11rem;
            letter-spacing: -22px;
            transform: translate(-10px);

        }
    }

    .title {
        color: var(--red);
        font-size: 1.7rem;
        font-weight: lighter;
    }

    .subTitle {
        color: var(--red);
        font-size: 2rem;
        margin-top: 10px;
        margin-bottom: 15px;
     @media only screen and (max-width: 600px) {
        font-size: 1.7rem;
     }
    }

    .text {
        font-size: 1.3rem;
    }
`