import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import NotFoundSection from './NotFoundStyle'

const NotFoundContent = () => {
    const { notFoundPage } = useStaticQuery(graphql`
    query {
        notFoundPage: sanityNotFoundPage {
            blockText
            description
            title
            subTitle
        }
    }
    `)
    return (
        <NotFoundSection>
            <div className="container">
                <div className="large-block">
                    <p className="large-block-text">{notFoundPage.blockText}</p>
                </div>
                <p className="title">{notFoundPage.subTitle}</p>
                <h1 className="subTitle">{notFoundPage.title}</h1>
                <p className="text">{notFoundPage.description}</p>
            </div>
        </NotFoundSection>
    )
}

export default NotFoundContent
